h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  font-weight: normal;
}

ul,
li {
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: var(--color-black);
}
