.help-container {
  background-color: var(--color-white);
  width: 100%;
  height: 100%;
  padding: 10px 15px;

  .help {
    &-title {
      font-size: var(--text-large);
      font-weight: 600;
      text-align: center;
    }

    &-effective-date {
      font-size: var(--text-small);
      text-align: center;
      margin-top: 10px;
    }

    &-content {
      margin-top: 20px;
    }

    &-table-content {
      color: var(--color-primary);

      ol {
        margin-left: 30px;
      } 

      li {
        list-style: decimal;
      }
    }

    &-terms-and-conditions {
      margin-top: 20px;

      h2 {
        font-size: var(--text-xMedium);
        font-weight: 600;
      }

      ol {
        margin-left: 30px;
      }

      li {
        list-style: upper-alpha;
        font-size: var(--text-small);
      }
      
      &-block {
        margin-top: 20px;

        .plain-content {
          font-size: var(--text-small);

          span, strong {
            font-size: var(--text-small);
          }
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: 5px;
          font-size: var(--text-small);

          span, strong {
            font-size: var(--text-small);
          }
        }

        .child-content {
          margin-left: 15px;
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
  }
}