.message-card-container {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  position: relative;
  border: 1px solid var(--color-medium-gray);

  .message-header {
    border-bottom: 1px solid var(--color-medium-gray);
    padding: 10px;
    background-color: var(--color-shadow-light);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);

    &-title {
      font-weight: 600;
    }

    &-subject {
      font-size: var(--text-small);
    }
  }

  .message-body {
    padding: 10px;
    border-bottom: 1px solid var(--color-medium-gray);

    &-date {
      font-size: var(--text-small);
      text-align: right;

      span {
        font-size: var(--text-small);
        font-weight: 600;
      }
    }

    &-description {
      padding-top: 2px;
      font-size: var(--text-small);
    }
  }

  .message-attachments {
    padding: 10px;
    background-color: var(--color-shadow-light);
    border-bottom: 1px solid var(--color-medium-gray);

    display: flex;
    flex-direction: column;
    gap: 3px;

    .message-attachment {
      display: flex;
      align-items: center;

      &-icon {
        display: flex;
      }
    }
  }

  .message-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    &-button {
      outline: none;
      border: none;
      background-color: transparent;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
