.tags {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  &-item {
    padding: 2px 10px;
    border-radius: 20px;
    text-align: center;
    text-transform: capitalize;
    font-size: var(--text-small);
    flex-wrap: wrap;
    cursor: pointer;
  }

  &.default {
    .tags-item {
      border: 2px solid var(--color-black);
      color: var(--color-black);
    }
  }

  &.success {
    .tags-item {
      border: 2px solid var(--color-green);
      color: var(--color-green);
    }
  }

  &.primary {
    .tags-item {
      border: 1px solid var(--color-primary-dark);
      background-color: var(--color-primary);
      color: var(--color-white);
    }
  }

  &.lg {
    gap: 10px;

    .tags-item {
      padding: 6px 12px;
      min-width: 80px;
    }
  }
}
