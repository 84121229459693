.manage-campaign-page-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .manage-campaign-page {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0 10px;

      h1 {
        font-size: var(--text-large);
        font-weight: 600;
      }

      button {
        display: flex;
        align-self: center;
        min-width: unset;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      overflow: visible;

      .campaign-card-container {
        width: 100%;

        min-height: 250px;
      }

      .campaign-table-description {
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .campaign-table-tags {
        flex-wrap: wrap;
      }

      .campaign-table-status {
        text-transform: capitalize;
        padding: 1px 10px;
        border-radius: var(--border-radius);
        font-size: var(--text-small);

        &.scheduled {
          background-color: var(--color-pending);
        }

        &.live {
          background-color: var(--color-green);
        }

        &.completed {
          background-color: var(--color-normal-gray);
        }

        &.cancelled {
          background-color: var(--color-error);
        }
      }

      .campaign-table-actions {
        display: flex;
        justify-content: center;
        gap: 7px;

        padding: 5px;

        button {
          height: 30px;
          min-width: unset;

          padding: 6px;
        }
      }
    }

    &-card {
      width: 100%;
    }

    &-empty {
      width: 100%;
      max-width: 500px;
      text-align: center;
      align-self: center;
      background-color: white;
      padding: 30px;
      border-radius: 15px;

      margin-top: 100px;

      h2 {
        font-size: 20px;
      }
    }
  }

  @media (min-width: 768px) {
    .manage-campaign-page {
      &-list {
        overflow: auto;

        .campaign-table-tags {
          flex-wrap: unset;
          overflow: auto;
        }
      }
    }
  }
}
