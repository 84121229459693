.editor-wrapper {
  width: 100%;
  border: 1px solid var(--color-medium-gray);
  background: var(--color-white);
  padding: 1rem;

  button {
    margin-right: 8px;
    font-size: 1rem;
    padding: 10px;
    border: none;
    background: var(--color-white);
    cursor: pointer;
    height: 42px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.editor-container {
  width: 100%;
  border: 1px solid var(--color-primary);
  padding: 1rem;
  min-height: 80px;
}

.toolbar-grid {
  display: flex;
  flex-wrap: wrap;
}

.leftAlign {
  text-align: left;
}

.rightAlign {
  text-align: right;
}

.centerAlign {
  text-align: center;
}