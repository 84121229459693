.messages-container {
  .messages-tab-info {
    text-align: right;
    font-size: var(--text-small);
  }

  .chip {
    width: 18px;
    height: 18px;
    background-color: var(--color-accent);
    display: inline-block;
    border-radius: 50%;
    color: var(--color-white);
    font-size: var(--text-extrasmall);
    text-align: center;
    margin-left: 2px;
  }

  .messages-list {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
