.hotel-create-modal-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .hotel-create-modal {
    &-heading {
      font-size: var(--text-large);
      font-weight: 600;
      width: 85%;
    }

    &-fields {
      display: flex;
      flex-direction: column;
      gap: 20px;

      label {
        font-size: var(--text-small);
        font-weight: 600;
        margin-bottom: 3px;
      }

      input {
        width: 100%;
      }
    }

    &-form-container { 
      display: flex;
      flex-direction: column;
      gap: 20px;

      .hotel-create-modal-form-fields {
        width: 100%;
      }
    }

    &-form-fields {
      display: flex;
      flex-direction: column;

      .rti--container {
        border: none;
        background: none;
        box-shadow: none;
        padding: 0;
      }
    }

    &-buttons {
      margin-top: 20px;
      
      display: flex;
      gap: 20px;

      button {
        width: 100%;
        min-width: unset;
      }
    }

    &-image-upload {
      width: 100%;
    }
  }

  .system-error {
    text-align: center;
    margin-top: 10px;
  }

  .image-form-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    .hotel-create-modal {
      &-image-upload {
        width: 200px;
      }

      &-form-container { 
        flex-direction: row;
        justify-content: space-between;

        .hotel-create-modal-form-fields {
          width: 50%;
        }
      }
    }

    .image-form-fields {
      flex-direction: row;
    }
  }
}