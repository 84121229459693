.campaign-card-container {
  display: flex;
  gap: 5px;
  background-color: var(--color-white);
  position: relative;
  border: 1px solid var(--color-medium-gray);
  cursor: pointer;

  min-height: 250px;
  max-height: 350px;

  .campaign-card-newlabel {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    span {
      color: var(--color-white);
      font-weight: bold;
      position: absolute;
      z-index: 1;
      left: 6px;
      top: 14px;
    }

    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 0;
      border-width: 40px;
      border-color: var(--color-accent) transparent transparent var(--color-accent);
      border-style: solid;
    }
  }

  .campaign-card-image {
    width: 140px;
    height: 250px;
    position: relative;

    img {
      min-width: 140px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-number {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      color: var(--color-white);
      background-color: rgba(0, 0, 0, 0.4);
      padding: 2px 6px;
      gap: 4px;

      span:first-child {
        transform: translateY(2px);
      }

      span:last-child {
        font-size: var(--text-small);
      }
    }

    .campaign-card-status {
      position: absolute;
      bottom: 0;
      z-index: 1;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 6px;

      font-size: var(--text-medium);

      &.pending {
        background-color: var(--color-pending);
      }

      &.accepted {
        background-color: var(--color-green);
      }

      &.rejected,
      &.cancelled {
        background-color: var(--color-error);
      }
    }
  }

  .campaign-card-info {
    flex: 1;
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    &-hotel-name {
      font-size: var(--text-normal);
      font-weight: 600;
      margin-bottom: 7px;

      &.hasRightData {
        max-width: 80%;
      }
    }

    &-campaign-name {
      font-size: var(--text-extrasmall);
      color: var(--color-normal-gray);
      padding-bottom: 4px;
    }

    &-desc {
      font-size: var(--text-below-extrasmall);
      line-height: 18px;

      & * {
        font-size: var(--text-below-extrasmall);
      }

      ul {
        list-style: disc;
        margin-left: 30px;
        width: fit-content;

        li {
          list-style: disc;
          font-size: var(--text-below-extrasmall);
        }
      }

      ol {
        list-style: decimal;
        margin-left: 30px;

        li {
          list-style: decimal;
          font-size: var(--text-below-extrasmall);
        }
      }
    }

    &-requirements {
      margin-top: 4px;
      font-size: var(--text-below-extrasmall);
      color: var(--color-normal-gray);

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-tags-container {
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      gap: 6px;

      .tags {
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;
        max-width: 100%;

        .tags-item {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: var(--text-below-extrasmall);
        }
      }
    }

    &-location {
      margin-top: 4px;
      color: var(--color-normal-gray);
      text-align: center;
      padding-right: 4px;

      span {
        font-size: var(--text-below-extrasmall);
        padding-left: 4px;
      }
    }
  }

  .campaign-card-actions {
    position: absolute;
    right: 0;

    button {
      min-width: unset;
      background: var(--color-white);
    }
  }

  .campaign-card-social-icons {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    gap: 10px;

    img {
      width: 18px;
      height: 18px;
    }
  }
}