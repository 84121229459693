.input-container {
  display: flex;
  flex-direction: column;

  .input {
    padding: 12px 14px;
    font-size: var(--text-normal);
    border-radius: var(--border-radius);
    border: 1px solid var(--color-medium-gray);
    outline: none;

    &:focus {
      outline: 2px solid var(--color-normal-gray);
    }
  }

  .input-label {
    display: inline-block;
    font-size: var(--text-small);
    margin-bottom: 6px;
    font-weight: 600;
    color: var(--color-normal-gray);
  }
}
