.campaign-calendar-container {
  .campaign-calendar {
    &-tile {
      padding: 10px 3.6667px;

      &-available {
        background-color: var(--color-light-green);
      }

      &-unavailable {
        background-color: var(--color-light-red);
      }

      abbr {
        font-size: var(--text-extrasmall);
      }
    }
  }

  .campaign-available-dates {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    gap: 10px;

    &-title {
      font-size: var(--text-small);
      color: var(--color-normal-gray);
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      padding: 5px 0;
      border-bottom: 2px solid var(--color-medium-gray);

      .campaign-available-date {
        padding: 5px;
        display: flex;
        gap: 5px;

        span {
          min-width: 70px;
        }

        input[type="checkbox"] {
          accent-color: var(--color-light-green);
          width: 20px;
          height: 20px;
        }
      }
    }

    .campaign-booking-section {
      padding: 5px 10px 15px;

      display: flex;
      justify-content: space-between;

      border-bottom: 2px solid var(--color-medium-gray);
    }
  }
}