.table {
  width: 100%;
  border-spacing: 0;
}

.sticky {
  position: sticky !important;
  top: 0;
  z-index: 1;
  background-color: var(--color-white);

  &.left {
    left: 0;
    box-shadow: 2px 0 0 var(--color-normal-gray);
  }

  &.right {
    right: 0;
    box-shadow: -2px 0 0 var(--color-normal-gray);
  }


}