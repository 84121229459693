:root {
  // colors
  --color-white: #ffffff;
  --color-background: #f8f8f8;
  --color-lightest-gray: #f2f1f2;
  --color-medium-gray: #e1e1e1;
  --color-normal-gray: #9c9da6;
  --color-dark-gray: #5c5d5d;
  --color-shadow-light: #f7f7ee;
  --color-medium-black: #222222;
  --color-black: #000000;
  --color-error: #ce433e;
  --color-error-dark: #b13935;
  --color-green: #548135;
  --color-light-green: #89D49A;
  --color-cream: #FFFDD0;
  --color-cream-with-opacity: #FFFDD050;
  --color-text-gray: #5C5D5D;
  --color-text-dark-gray: #404040;
  --color-light-red: #FFD0D0;
  --color-background-overlay: #00000050;
  --color-medium-gray-with-opacity: #E1E1E140;
  --color-required: #FF0000;
  --color-pending: #E09C36;

  // theme colors
  --color-primary: #224f59;
  --color-primary-dark: #1c414a;
  --color-accent: #d9147d;

  // font sizes
  --text-large: 24px;
  --text-xMedium: 20px;
  --text-medium: 18px;
  --text-normal: 16px;
  --text-small: 14px;
  --text-extrasmall: 12px;
  --text-below-extrasmall: 10px;

  // border radius
  --border-radius: 8px;
  --header-button-border-radius: 20px;

  --shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);
  --slider-image-height: 240px;
}

// custom fonts
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  font-family: 'Poppins', sans-serif, 'Cormorant Garamond', Georgia,
    'Times New Roman', serif;
  font-size: var(--text-normal);
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

body {
  background-color: #f3f3f7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: auto;

  @media (min-width: 768px) {
    overflow-y: hidden;

    &:hover {
      overflow-y: auto;
    }
  }
}

body.bg-white {
  background-color: var(--color-white);
}

input,
select {
  padding: 12px 14px;
  font-size: var(--text-normal);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-medium-gray);
  outline: none;

  &:focus {
    outline: 2px solid var(--color-normal-gray);
  }
}

textarea {
  padding: 12px 14px;
  font-size: var(--text-normal);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-medium-gray);
  outline: none;
}

table {
  border: 1px solid lightgray;
  border-radius: var(--border-radius);
}

tbody {
  border-bottom: 1px solid lightgray;
}

th {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;

  &:last-child {
    border-right: none;
  }
}

tr {
  td {
    border-bottom: 1px solid lightgray;
  }

  &:last-child {
    td {
      border-bottom: none;
    }
  }
}

td {
  border-right: 1px solid lightgray;
  padding: 2px 4px;

  &:last-child {
    border-right: none;
  }
}

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}

.required {
  color: var(--color-required);
}