.about-container {
  .about-description {
    padding: 20px 10px;
    border-top: 1px solid var(--color-medium-gray);
  }

  .about-board-members {
    padding: 0px 20px;

    &-item {
      list-style-type: disc;
      font-weight: 600;
      margin: 4px 0;
    }
  }
}
