.hotel-card-container {
  display: flex;
  gap: 5px;
  background-color: var(--color-white);
  position: relative;
  border: 1px solid var(--color-medium-gray);
  cursor: pointer;
  width: 100%;

  .hotel-card-newlabel {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    span {
      color: var(--color-white);
      font-weight: bold;
      position: absolute;
      z-index: 1;
      left: 6px;
      top: 14px;
    }

    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 0;
      border-width: 40px;
      border-color: var(--color-accent) transparent transparent var(--color-accent);
      border-style: solid;
    }
  }

  .hotel-card-image {
    width: 140px;
    height: 250px;
    position: relative;

    img {
      min-width: 140px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-number {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      color: var(--color-white);
      background-color: rgba(0, 0, 0, 0.4);
      padding: 2px 6px;
      gap: 4px;

      span:first-child {
        transform: translateY(2px);
      }

      span:last-child {
        font-size: var(--text-small);
      }
    }
  }

  .hotel-card-info {
    flex: 1;
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    &-name {
      font-size: var(--text-normal);
      font-weight: 600;
      margin-bottom: 10px;
    }

    &-classification {
      font-size: var(--text-extrasmall);
      margin-bottom: 4px;
    }

    &-desc {
      font-size: var(--text-below-extrasmall);
      line-height: 18px;

      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-tags-container {
      margin-top: 8px;

      & .tags {
        justify-content: center;
        gap: 10px;

        & .tags-item {
          font-size: var(--text-below-extrasmall);
        }
      }
    }

    &-location {
      margin-top: 4px;
      color: var(--color-normal-gray);
      padding-right: 4px;

      span {
        font-size: var(--text-below-extrasmall);
        font-weight: 700;
        padding-left: 4px;
      }
    }
  }
}