.confirm-enquiry-modal-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .confirm-enquiry-modal {
    &-heading {
      font-size: var(--text-large);
      font-weight: 600;
      width: 85%;
    }

    &-subheading {
      font-size: var(--text-medium);
      font-weight: 600;
    }

    &-requirements {
      font-weight: 600;
      font-size: var(--text-small);
    }

    &-description {
      font-size: var(--text-small);

      & * {
        font-size: var(--text-small);
      }

      ul {
        list-style: disc;
        margin-left: 30px;

        li {
          list-style: disc;
          font-size: var(--text-small);
        }
      }

      ol {
        list-style: decimal;
        margin-left: 30px;

        li {
          list-style: decimal;
          font-size: var(--text-small);
        }
      }
    }

    &-buttons {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        button {
          width: 50%;
          min-width: 150px;
        }
      }

      &-submit {
        background-color: var(--color-green);
        color: var(--color-white);

        &:hover {
          background-color: darken(#548135, 10%);
        }
      }

      &-withdraw {
        background-color: var(--color-error);
        color: var(--color-white);

        &:hover {
          background-color: darken(#ce433e, 10%);
        }
      }
    }
  }

  .system-error {
    text-align: center;
    margin-bottom: 10px;
  }

  .campaign-calendar {
    &-tile {
      padding: 10px 3.6667px;

      &-available {
        background-color: var(--color-light-green);
      }

      &-unavailable {
        background-color: var(--color-light-red);
      }

      abbr {
        font-size: var(--text-extrasmall);
      }
    }
  }

  .campaign-available-dates {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    gap: 10px;

    &-title {
      font-size: var(--text-small);
      color: var(--color-normal-gray);
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      padding: 5px 0;

      .campaign-available-date {
        padding: 5px;
        display: flex;
        gap: 5px;

        span {
          min-width: 70px;
        }

        input[type="checkbox"] {
          accent-color: var(--color-light-green);
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}