.app-layout-container {
  .app-layout-title {
    text-align: center;
    margin: 20px;
    font-weight: bold;
    font-size: var(--text-small);
    margin-top: 100px;
  }

  .app-layout-content {
    margin-top: 80px;
    &.with-title {
      margin-top: 0;
    }
  }

  @media (min-width: 768px) {
    height: 100vh;
    overflow-y: auto;

    .app-layout-content {
      margin-top: 0;
    }

    .app-layout-title {
      margin-top: 20px;
    }
  }
}
