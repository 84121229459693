.campaign-actions-container {
  display: flex;
  justify-content: center;
  gap: 24px;
  background-color: #fcfdf4;
  padding: 20px;

  width: 100%;

  .campaign-action-button {
    width: 60px;
    height: 60px;
    background-color: var(--color-medium-gray);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    transition: background-color 0.2s;
    border-width: 0;

    &.danger {
      background-color: var(--color-error);
    }

    &.active {
      &.check {
        background-color: var(--color-green);
      }

      &.message {
        background-color: var(--color-primary);
      }
    }
  }
}