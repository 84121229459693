.home-container {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  .homecard {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-shadow-light);
    border: 1px solid var(--color-medium-gray);
    padding: 20px;
    border-radius: var(--border-radius);
    cursor: pointer;

    &:hover {
      background-color: var(--color-lightest-gray);
    }

    &-icon {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-primary);
    }

    &-title {
      text-align: center;
      font-size: var(--text-small);
      font-weight: 600;
      color: var(--color-primary);
    }

    &-newlabel {
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: var(--text-small);
      background-color: var(--color-accent);
      color: var(--color-white);
      padding: 0px 4px;
      min-width: 40px;
      text-align: center;
      border-radius: var(--border-radius);
    }
  }
}
