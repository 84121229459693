.campaign-options {
  border-top: 1px solid var(--color-medium-gray);

  li.campaign-options-item {
    display: flex;
    gap: 20px;
    padding: 14px 20px;
    border-bottom: 1px solid var(--color-medium-gray);
    align-items: center;
    cursor: pointer;

    div:first-child {
      flex: 1;
    }

    div:last-child {
      input[type='checkbox'] {
        width: 20px;
        height: 20px;
      }
    }

    &.disabled {
      color: var(--color-normal-gray);
      cursor: default;
    }

    & input[type="checkbox"] {
      accent-color: var(--color-primary-dark);
    }
  }
}

.detail-modal-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  height: 100%;

  ul {
    list-style: disc;
    margin-left: 30px;
    li {
      list-style: disc;
      font-size: var(--text-small);
    }
  }

  ol {
    list-style: decimal;
    margin-left: 30px;
    li {
      list-style: decimal;
      font-size: var(--text-small);
    }
  }

  .detail-modal {
    &-heading {
      font-size: var(--text-large);
      font-weight: 600;
    }

    &-description {
      min-height: 70vh;
      overflow: auto;
    }

    &-footer {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }
    }
  }
}

.detail-action-button {
  width: 60px;
  height: 60px;
  background-color: var(--color-medium-gray);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  transition: background-color 0.2s;
  border: 1px solid var(--color-primary);

  &.active {
    &.check {
      background-color: var(--color-green);
    }
  }

  &.next {
    color: var(--color-primary);
  }
}