.faq-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 30px;

  max-width: 1024px;
  margin: 0 auto;

  .faq {
    &-question {
      font-weight: 700;
    }
  }
}
