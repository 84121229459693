.forgot-password-container {
  background-color: var(--color-primary);
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding: 0px 40px;

  .forgot-password-wrapper {
    width: inherit;
  }

  .forgot-password {
    &-logo {
      margin-top: 100px;
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 200px;
      }
    }

    &-title {
      font-weight: 500;
      font-size: var(--text-medium);
      text-align: center;
      color: var(--color-white);
      margin: 10px 5px;
    }

    &-fields {
      display: flex;
      flex-direction: column;
      gap: 20px;

      input {
        width: 100%;
      }
    }

    &-button {
      margin-top: 20px;

      button {
        width: 100%;
      }
    }
  }

  .system-error {
    text-align: center;
    margin-top: 10px;
  }

  .system-success {
    text-align: center;
    margin-top: 15px;
    color: var(--color-light-green);
    padding: 0 10px;
  }

  .no-account {
    font-size: var(--text-small);
    color: var(--color-white);
    text-align: center;
    margin-top: 30px;

    .signup-link {
      font-size: var(--text-small);
      color: var(--color-white);
      font-weight: bold;
      margin-top: 4px;
      text-decoration: underline;
    }
  }

  @media (min-width: 768px) {
    .forgot-password-wrapper {
      max-width: 450px;
      margin: 0 auto;
      padding: 40px 0;
    }
  }
}
