.campaign-detail-container {
  .campaign-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;

    &-left {
      max-width: 120px;
      width: 100%;
      text-align: center;

      h2 {
        border-bottom: 2px solid var(--color-medium-gray);
        padding-bottom: 4px;
        margin-bottom: 4px;
        font-size: var(--text-medium);
      }

      h5 {
        font-size: var(--text-small);
      }
    }

    &-right {
      text-align: right;
      font-size: var(--text-medium);
    }
  }
}