.campaign-view-modal-wrapper {
  display: flex;
  flex-direction: column;

  .campaign-view-modal {
    &-images {
      img {
        height: 190px;
        width: 100%;
        object-fit: cover;
      }
    }

    &-info-container {
      padding: 10px 20px 20px;
    }

    &-title {
      font-size: var(--text-large);
      font-weight: 600;
      margin-bottom: 10px;
    }

    &-schedule {
      font-size: var(--text-small);
      font-weight: 400;
      margin-bottom: 10px;
    }

    &-location {
      .google-map-container {
        height: auto;
      }
    }

    &-offers {
      padding: 10px;

      .offer-dates {
        list-style: disc;

        li {
          list-style: disc;
        }
      }

      .offer-status {
        padding: 1px 10px;
        border-radius: var(--border-radius);

        &.pending {
          background-color: var(--color-pending);
        }

        &.accepted {
          background-color: var(--color-green);
        }

        &.rejected,
        &.cancelled {
          background-color: var(--color-error);
        }
      }
    }

    &-availability {
      .campaign-view-modal {
        &-tile {
          padding: 10px 3.6667px;

          &-available {
            background-color: var(--color-light-green);
          }

          &-unavailable {
            background-color: var(--color-light-red);
          }

          abbr {
            font-size: var(--text-extrasmall);
          }
        }
      }
    }
  }
}

.campaign-view-modal-container {
  background-color: var(--color-background-overlay);

  @media (min-width: 768px) {
    .modal-content {
      margin: 5% auto 15% auto;
      background-color: var(--color-background);
      border-radius: 15px;
      max-width: 65%;
      padding: 0;

      display: flex;
      flex-direction: column;
      gap: 20px;

      position: relative;
      overflow: auto;

      height: 80%;

      .modal-close {
        right: 10px;
        top: 10px;
      }
    }
  }

  @media (min-width: 1024px) {
    .modal-content {
      max-width: 53%;
    }
  }
}

.campaign-view-modal-container-mobile {
  .modal-content {
    padding: 0;

    .tab-navigation-item {
      padding: 10px;
    }
  }
}
