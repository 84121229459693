.user-view-modal-wrapper {
  display: flex;
  flex-direction: column;

  .user-view-modal {
    &-image {
      img {
        height: 200px;
        width: 100%;
        object-fit: cover;
      }
    }

    &-info-container {
      padding: 10px 20px 20px;
    }

    &-name {
      font-size: var(--text-large);
      font-weight: 600;
    }

    &-city {
      font-size: var(--text-medium);
      font-weight: 300;
      margin-bottom: 10px;
    }

    &-bio {
      font-size: var(--text-small);
      font-weight: 400;
      margin-bottom: 10px;
      color: var(--color-dark-gray);
    }

    &-social {
      padding: 10px 0;

      &-link {
        display: flex;
        gap: 5px;

        a {
          color: var(--color-primary);
        }
      }
    }
  }
}

.user-view-modal-container {
  background-color: var(--color-background-overlay);

  @media (min-width: 768px) {
    .modal-content {
      margin: 5% auto 15% auto;
      background-color: var(--color-background);
      border-radius: 15px;
      max-width: 65%;
      padding: 0;

      display: flex;
      flex-direction: column;
      gap: 20px;

      position: relative;
      overflow: auto;

      height: 65%;

      .modal-close {
        right: 10px;
        top: 10px;
      }
    }
  }

  @media (min-width: 1024px) {
    .modal-content {
      max-width: 30%;
    }
  }
}

.user-view-modal-container-mobile {
  .modal-content {
    padding: 0;
  }
}
