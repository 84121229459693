.header-container {
  background-color: var(--color-primary);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 0;
  z-index: 2;

  .header-logo {
    cursor: pointer;

    img {
      width: 80px;
    }
  }

  .header-back-button {
    padding: 0 30px;
    border: 1px solid var(--color-white);
    background-color: transparent;
    color: var(--color-white);
    border-radius: var(--header-button-border-radius);
    font-size: var(--text-small);
  }

  .header-action {
    width: 100px;
  }

  @media (min-width: 768px) {
    position: unset;
    box-shadow: none;
  }
}

.admin-header-container {
  background-color: var(--color-primary);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
  position: fixed;
  padding: 0 20px;
  z-index: 8;

  .admin-header {
    &-navbar {
      &-button {
        min-width: unset;

        display: flex;
        align-items: center;
      }
    }

    &-logo {
      img {
        width: 80px;
      }
    }

    &-action {
      width: 44px;
    }
  }

  @media (min-width: 768px) {
    position: relative;
    justify-content: flex-start;

    .admin-header {
      &-navbar {
        display: none;
      }
    }
  }
}

.admin-navbar-mobile {
  height: 100%;
  width: 0;
  z-index: 10;
  background-color: var(--color-primary);
  transition: 0.1s;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;

  &.active {
    width: 70%;
  }

  .admin-navbar {
    &-profile {
      &-section {
        display: flex;
        gap: 20px;
        align-items: center;
        color: var(--color-white);
        background-color: var(--color-primary-dark);

        padding: 10px 20px;
      }

      &-image {
        img {
          width: 64px;
          height: 64px;
          border-radius: 50%;
        }
      }

      &-name {
        display: flex;
        flex-direction: column;

        font-size: var(--text-normal);
        font-weight: 700;

        text-transform: capitalize;

        &__role {
          font-size: var(--text-small);
          font-weight: 400;
        }
      }
    }
  }

  .admin-navbar-container {
    width: 100%;
  }
}