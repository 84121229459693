.manage-user-page-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .manage-user-page {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0 10px;

      h1 {
        font-size: var(--text-large);
        font-weight: 600;
      }

      button {
        display: flex;
        align-self: center;
        min-width: unset;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      overflow: visible;

      .user-table-actions {
        display: flex;
        justify-content: center;
        gap: 7px;

        padding: 5px;

        button {
          height: 30px;
          min-width: unset;

          padding: 6px;
        }
      }
    }

    &-card {
      width: 100%;
    }

    &-empty {
      width: 500px;
      text-align: center;
      align-self: center;
      background-color: white;
      padding: 30px;
      border-radius: 15px;

      margin-top: 100px;

      h2 {
        font-size: 20px;
      }
    }
  }

  .user-table-social-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
