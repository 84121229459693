.campaign-action-wrapper {
  border: 1px solid var(--color-white);
  border-radius: var(--header-button-border-radius);
  padding: 0 10px;
  display: flex;
  justify-content: center;

  & .campaign-action-btn {
    background-color: transparent;
    border: none;
    height: 21px;
    color: var(--color-white);
    font-size: var(--text-small);
  }
}
