.image-area {
  width: 100%;
  height: 200px;
  position: relative;
  text-align: center;

  input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .file-dummy {
    width: 100%;
    padding: 30px;
    background: var(--color-cream-with-opacity);
    border: 2px dashed var(--color-normal-gray);
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: var(--text-large);
    font-weight: 600;
    color: var(--color-text-gray);
  }

  .preview-image-container {
    width: inherit;
    height: inherit;
    position: relative;

    .preview-image {
      width: inherit;
      height: 200px;
      object-fit: cover;
      align-self: center;
      position: relative;

      &.is-profile-image {
        border-radius: 50%;
        box-shadow: 0px 0px 10px 0px var(--color-background-overlay);
      }
    }

    button {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: var(--color-white);
      border: none;
      border-radius: 50%;
      padding: 0;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      z-index: 4;
    }
  }
}

.crop-image-modal {
  .crop-image-modal-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .crop-image-modal {
      &-heading {
        font-size: var(--text-large);
        font-weight: 600;
        width: 85%;

        padding: 20px;
      }

      &-description {
        font-size: var(--text-small);
        font-weight: 400;

        padding: 0 20px;
      }

      &-buttons {
        margin-top: 20px;
        padding: 0 20px;

        display: flex;
        gap: 20px;

        button {
          width: 100%;
          min-width: unset;
        }
      }
    }

    .crop-image-tools-container {
      display: flex;
      gap: 20px;
      justify-content: flex-start;
      align-items: center;

      width: 100%;
      padding: 0 20px;

      button {
        width: 32px;
        height: 32px;
        min-width: unset;
        min-height: unset;
        padding: 0;

        svg {
          width: 16px;
          height: 16px;
          margin-top: 7px;
        }
      }
    }
  }

  .modal-content {
    padding: 0;
  }

  @media (min-width: 768px) {
    background-color: var(--color-background-overlay);

    .modal-content {
      margin: 5% auto 15% auto;
      max-width: 60%;
      height: 85%;
      padding: 10px 20px;

      .crop-image-modal {
        &-heading {
          padding: 0;
        }

        &-description {
          padding: 0;
        }
      }

      .crop-image-tools-container {
        padding: 0;
      }
    }
  }
}
