.email-change-confirmation-modal-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .email-change-confirmation-modal {
    &-heading {
      font-size: var(--text-large);
      font-weight: 600;
      width: 85%;
    }

    &-actions {
      margin-top: 20px;

      display: flex;
      gap: 20px;

      button {
        width: 100%;
        min-width: unset;
      }
    }
  }

  .system-error {
    text-align: center;
    margin-top: 10px;
  }

  @media (min-width: 768px) {
    .email-change-confirmation-modal {
      &-form-row {
        flex-direction: row;
        justify-content: space-between;

        .email-change-confirmation-modal-form-row-item {
          width: 50%;
        }
      }
    }
  }
}

.email-change-confirmation-modal {
  background-color: var(--color-background-overlay);

  @media (min-width: 768px) {
    .modal-content {
      margin: 25% auto;
      // background-color: var(--color-background);
      border-radius: 15px;
      max-width: 60%;
      padding: 20px;

      display: flex;
      flex-direction: column;
      gap: 20px;

      position: relative;
      overflow: auto;

      height: auto;

      .modal-close {
        right: 10px;
        top: 10px;
      }
    }
  }

  @media (min-width: 1024px) {
    .modal-content {
      margin: 15% auto;
      max-width: 54%;
    }
  }
}
