.campaign-action-menu {

  &-list {
    .modal-content {
      width: 90px;
      height: fit-content;
      padding: 5px;
      border-radius: var(--border-radius);

      position: absolute;

      .modal-close {
        display: none;
      }

      .campaign-action-menu-list-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        button {
          width: 80px;
          min-width: unset;
          padding: 5px;
          font-size: var(--text-extrasmall);

          background-color: var(--color-white);

          &:hover {
            background-color: darken(#f1f1f1, 4%);
          }

          &:disabled {
            color: var(--color-normal-gray);
            background-color: var(--color-medium-gray);
          }
        }
      }
    }
  }
}