.admin-layout-container {
  height: 100vh;

  .admin-layout {
    display: flex;

    &-sidenav {
      display: none;
    }

    &-content {
      padding: 0;
      margin-top: 95px;
      flex: 1;
    }
  }

  @media (min-width: 768px) {
    .admin-layout {
      &-sidenav {
        display: block;
        height: 100%;
      }

      &-content {
        padding: 0 20px;
        overflow: auto;
        margin-top: 10px;
      }
    }
  }
}