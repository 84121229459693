.navbar-container {
  display: flex;
  flex-direction: column;

  .navbar-list {
    padding: 10px;
    border-bottom: 1px solid var(--color-shadow-light);
    
    &-item {
      padding: 10px;
      color: var(--color-white);
    }
  }

  @media (min-width: 768px) {
    width: 200px;
    gap: 5px;

    .navbar-list {
      margin-left: 10px;

      border-bottom: none;
      
      &-item {
        display: block;
        font-weight: normal;
        color: var(--color-black);
        background-color: var(--color-medium-gray);
        border-radius: var(--border-radius);
  
        &:hover {
          color: var(--color-black);
          background-color: var(--color-medium-gray-with-opacity);
        }
  
        &.active {
          color: var(--color-white);
          background-color: var(--color-primary);
        }
      }
    }
  }

  @media (min-width: 1024px) {
    width: 270px;

    .navbar-list {
      &-item {
        padding: 15px;
      }
    }
  }
}
