.campaign-detail-container {
  .campaign-detail-image {
    img {
      height: var(--slider-image-height);
      width: 100%;
      object-fit: cover;
    }
  }

  .campaign-detail-tags {
    padding: 0 20px;

    .tag-label {
      margin-top: 8px;
      font-size: var(--text-small);
      color: var(--color-normal-gray);
    }

    .tag-list {
      margin-top: 4px;
    }
  }

  .campaign-detail-options {
    margin-top: 20px;
  }
}