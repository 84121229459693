.hotel-detail-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px;

  h2 {
    font-size: var(--text-large);
    font-weight: 600;
    color: var(--color-text-dark-gray);
    text-align: center;
  }

  span {
    font-size: var(--text-extrasmall);
    font-weight: 500;
    color: var(--color-text-dark-gray);
    text-align: center;
  }
}

.hotel-detail-container {
  .hotel-detail-image {
    img {
      height: var(--slider-image-height);
      width: 100%;
      object-fit: cover;
    }
  }

  .hotel-detail-tags {
    padding: 0 20px 12px;

    .tag-label {
      margin-top: 8px;
      font-size: var(--text-normal);
      font-weight: 600;
      color: var(--color-text-dark-gray);
    }

    .tag-list {
      margin-top: 4px;

      .tags {
        justify-content: space-between;
      }
    }
  }

  .hotel-detail-title {
    margin-top: 8px;
    font-size: var(--text-normal);
    font-weight: 600;
    color: var(--color-text-dark-gray);
  }

  .hotel-detail-overview {
    padding: 0 20px 12px;
    border-top: 1px solid var(--color-medium-gray);

    .hotel-detail-description {
      font-size: var(--text-small);
      font-weight: 500;
    }
  }

  .hotel-detail-campaigns {
    padding: 0 0 12px;

    .hotel-detail-title {
      padding: 0 20px 10px;
    }

    &-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}