.file-area {
  width: 100%;
  height: 100px;
  position: relative;
  text-align: center;

  input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .file-dummy {
    width: 100%;
    padding: 10px;
    background: var(--color-cream-with-opacity);
    border: 2px dashed var(--color-normal-gray);
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: var(--text-large);
    font-weight: 600;
    color: var(--color-text-gray);
  }
}
