.button {
  padding: 12px 14px;
  font-size: var(--text-normal);
  border-radius: var(--border-radius);
  border: none;
  outline: none;
  font-weight: 600;
  transition: background-color 0.2s;
  min-width: 240px;
  cursor: pointer;

  &.default {
    background-color: var(--color-lightest-gray);
    color: var(--color-black);

    &:hover,
    &:focus {
      background-color: darken(#f1f1f1, 4%);
    }
  }

  &.primary {
    background-color: var(--color-primary);
    color: var(--color-white);

    &:hover,
    &:focus {
      background-color: var(--color-primary-dark);
    }
  }

  &.secondary {
    background-color: var(--color-cream);
    color: var(--color-black);

    &:hover,
    &:focus {
      background-color: var(--color-cream);
    }
  }

  &.danger {
    background-color: var(--color-error);
    color: var(--color-white);

    &:hover,
    &:focus {
      background-color: var(--color-error-dark);
    }
  }

  &:disabled {
    background-color: var(--color-normal-gray);
    color: var(--color-black);
    cursor: not-allowed;

    &:hover,
    &:focus {
      background-color: var(--color-normal-gray);
    }
  }
}