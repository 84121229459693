.user-card-container {
  display: flex;
  gap: 5px;
  background-color: var(--color-white);
  position: relative;
  border: 1px solid var(--color-medium-gray);
  cursor: pointer;

  height: 250px;

  .user-card {
    &-image {
      width: 140px;
      height: 250px;
      position: relative;

      img {
        min-width: 140px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-info {
      flex: 1;
      padding: 4px;
      display: flex;
      flex-direction: column;
      text-align: center;

      &-name {
        font-size: var(--text-normal);
        font-weight: 600;
        margin-bottom: 10px;
      }

      &-city {
        font-size: var(--text-extrasmall);
        margin-bottom: 4px;
      }

      &-bio {
        font-size: var(--text-below-extrasmall);
        line-height: 18px;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &-interests {
        margin: 8px 0;

        & .tags {
          justify-content: center;
          gap: 10px;

          & .tags-item {
            font-size: var(--text-below-extrasmall);
          }
        }
      }

      &-social {
        margin-top: auto;
        display: flex;
        justify-content: center;

        &-wrapper {
          display: flex;
          justify-content: center;
          gap: 10px;
          padding-top: 10px;
          border-top: 1px solid var(--color-medium-gray);
        }

        a {
          img {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
