.signup-container {
  margin-bottom: 40px;

  .signup {
    &-fields {
      display: grid;
      grid-template-columns: 1fr;
      padding: 0px 40px;
      gap: 20px;

      input[type="text"] {
        width: 100%;
      }

      &-user {
        display: flex;
        justify-content: space-between;

        .signup-user-type {
          display: flex;
          flex-direction: row-reverse;
          gap: 5px;

          color: var(--color-black);

          cursor: pointer;
        }
      }
    }

    &-button {
      padding: 0px 40px;
      margin-top: 40px;

      button {
        width: 100%;
      }
    }
  }

  .already-registered {
    font-size: var(--text-small);
    text-align: center;
    margin-top: 30px;

    a {
      padding-left: 4px;
      color: var(--color-black);
      font-size: var(--text-small);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 768px) {
    margin: 0 auto;
    max-width: 450px;

    .already-registered {
      color: var(--color-white);

      a {
        color: var(--color-white);
      }
    }

    .signup-fields {
      .signup-user-type {
        color: var(--color-white);
      }
    }

  }
}

.signup-layout {
  @media (min-width: 768px) {
    background-color: var(--color-primary);

    .app-layout-title {
      margin-top: 30px;
      color: var(--color-white);
    }
  }
}

.error {
  color: var(--color-error);
  font-size: var(--text-small);
}

.interest-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .interest-form-field {
    display: flex;
    gap: 10px;

    .tags {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .interest-input,
    .tags-item {
      width: auto;
      min-width: 60px;
      padding: 5px 10px;
      border: 1.5px solid var(--color-black);
      border-radius: 20px;
      text-align: center;

      &:disabled {
        border: 1.5px solid var(--color-normal-gray);
        color: var(--color-normal-gray);
      }
    }
  }
}