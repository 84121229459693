.profile-submit-button {
  padding: 0 30px;
  border: 1px solid var(--color-white);
  background-color: transparent;
  color: var(--color-white);
  border-radius: var(--header-button-border-radius);
  font-size: var(--text-small);
}

.profile-container {
  .form-group {
    display: flex;
    justify-content: flex-start;
    margin: 10px 20px;
    gap: 30px;

    label {
      width: 100px;
      color: var(--color-text-gray);
      font-size: var(--text-small);
    }

    input,
    textarea {
      background-color: transparent;
      outline: none;
      border: 1px solid var(--color-normal-gray);
      border-radius: 8px;
      padding: 3px;

      min-width: 54vw;
      font-size: var(--text-extrasmall);

      width: 100%;
    }

    textarea {
      resize: none;
      height: 100px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 54vw;

      gap: 5px;
    }

    .error-message {
      color: var(--color-error);
      font-size: var(--text-extrasmall);
    }

  }

  .profile-image-upload {
    width: 200px;
    margin: 0 auto;
  }

  .channel-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .section-heading {
      font-size: var(--text-small);
      color: var(--color-text-gray);
    }

    .channel-form-field {
      position: relative;
      display: flex;
      gap: 20px;

      img {
        width: 41px;
        height: 41px;
      }

      .input-container {
        width: 100%;
      }

      .input {
        width: 100%;
        padding: 9px;
        padding-right: 80px;

        font-size: var(--text-small);
      }

      button {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: var(--text-extrasmall);
        border: none;
        padding: 4px 8px;
        border-radius: var(--border-radius);
        background-color: var(--color-dark-gray);
        color: var(--color-white);
      }
    }
  }

  .interest-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .interest-form-field {
      display: flex;
      gap: 20px;

      .tags {
        width: 100%;

        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }

      .interest-input,
      .tags-item {
        width: auto;
        min-width: 100px;
        padding: 5px 10px;
        border: 1.5px solid var(--color-black);
        border-radius: 20px;
        text-align: center;

        &:disabled {
          border: 1.5px solid var(--color-normal-gray);
          color: var(--color-normal-gray);
        }
      }
    }
  }

  .profile-calendar-section {
    .profile-calendar-tile {
      padding: 10px 3.6667px;

      &-available {
        background-color: var(--color-light-green);
      }

      abbr {
        font-size: var(--text-extrasmall);
      }
    }
  }
}

.tag-modal-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .tag-modal {
    &-heading {
      font-size: var(--text-large);
      font-weight: 600;
    }

    &-list {
      .tags {
        gap: 12px;
      }
    }
  }
}