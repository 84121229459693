.custom-calendar {
  .react-calendar__viewContainer {
    margin: unset;
    justify-content: space-between;

    .react-calendar__month-view {
      width: 45%;

      .react-calendar__month-view__weekdays {
        text-transform: capitalize;

        .react-calendar__month-view__weekdays__weekday {
          padding: 0.5em 0.25em;

          abbr {
            font-size: var(--text-extrasmall);
          }
        }
      }
    }
  }
}