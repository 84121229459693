.password-change-modal-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .password-change-modal {
    &-heading {
      font-size: var(--text-large);
      font-weight: 600;
      width: 85%;
    }

    &-description {
      font-size: var(--text-medium);
      font-weight: 600;
    }

    &-fields {
      display: flex;
      flex-direction: column;
      gap: 20px;

      input {
        width: 100%;
      }
    }

    &-buttons {
      margin-top: 20px;

      button {
        width: 100%;
      }
    }
  }

  .system-error {
    text-align: center;
    margin-top: 10px;
  }
}