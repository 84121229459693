.profile-container {
  .profile-form {
    padding: 10px;
    border-top: 1px solid var(--color-medium-gray);

    .form-field {
      margin-bottom: 10px;

      .form-field-label {
        display: block;
        font-size: var(--text-small);
        margin-bottom: 6px;
        font-weight: 600;
        color: var(--color-normal-gray);
      }

      .input {
        width: 100%;
      }
    }

    .channel-section {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 10px;

      .section-heading {
        font-weight: 600;
        font-size: var(--text-small);
      }

      .channel-form-field {
        position: relative;

        img {
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
        }

        .input {
          width: 100%;
          padding-left: 40px;
          padding-right: 80px;
        }

        button {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          font-size: var(--text-extrasmall);
          border: none;
          padding: 4px 8px;
          border-radius: var(--border-radius);
        }
      }
    }
  }
}

.profile-edit-button {
  padding: 0 30px;
  border: 1px solid var(--color-white);
  background-color: transparent;
  color: var(--color-white);
  border-radius: var(--header-button-border-radius);
  font-size: var(--text-small);
}
