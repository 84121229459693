.feedback-container {
  max-width: 1024px;
  margin: 0 auto;

  .feedback-info {
    margin: 10px 20px;
    font-size: var(--small);

    a {
      color: var(--color-primary);
    }
  }

  .feedback-form {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 20px;
  }

  button {
    margin-top: 20px;
  }

  .feedback-form-item {
    align-self: stretch;

    display: flex;
    justify-content: flex-start;
    gap: 8px;
    margin: 10px 20px;

    label {
      width: 100px;
      color: var(--color-text-gray);
      font-size: var(--text-small);
    }

    &-col {
      flex-direction: column;
      width: unset;

      label {
        width: unset;
      }
    }

    input,
    textarea {
      background-color: transparent;
      outline: none;
      border: 1px solid var(--color-normal-gray);
      border-radius: 8px;
      padding: 3px;

      min-width: 54vw;
      font-size: var(--text-extrasmall);

      width: 100%;
    }

    textarea {
      resize: none;
      height: 100px;
    }

    .error-message {
      color: var(--color-error);
      font-size: var(--text-extrasmall);
    }
  }
}
