.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 9;

  .modal-content {
    background-color: white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 20px;
    overflow-y: auto;
    z-index: 10;

    .modal-close {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--color-lightest-gray);
      display: flex;
      align-items: center;
      justify-content: center;
      border-width: 0;
      z-index: 2;

      &:hover {
        background-color: var(--color-medium-gray);
      }
    }
  }
}

.alert-modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: var(--color-background-overlay);
  padding-top: 50px;

  .alert-modal-content {
    margin: 50% 20px;
    background-color: var(--color-background);
    border-radius: 15px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    .alert-modal-title {
      font-size: var(--text-large);
      font-weight: 600;
    }

    .alert-modal-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;

      button {
        min-width: 40%;
      }
    }
  }


  @media (min-width: 768px) {
    .alert-modal-content {
      margin: 5% auto 15% auto;

      max-width: 40%;
    }
  }
}