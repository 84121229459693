.manage-offer-modal-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .manage-offer-modal {
    &-heading {
      font-size: var(--text-large);
      font-weight: 600;
      width: 85%;
    }

    &-influencers,
    &-offers {
      padding: 10px 0;
      overflow: auto;

      td {
        font-size: var(--text-small);

        * {
          font-size: var(--text-small);
        }
      }
    }

    &-influencers {
      .influencer-actions {
        display: flex;
        justify-content: center;
        gap: 7px;

        padding: 5px;

        button {
          height: 30px;
          min-width: unset;

          padding: 6px 8px;
        }
      }
    }

    &-offers {
      .offer-dates {
        list-style: disc;

        li {
          list-style: disc;
        }
      }

      .offer-status {
        padding: 1px 10px;
        border-radius: var(--border-radius);

        &.pending {
          background-color: var(--color-pending);
        }

        &.accepted {
          background-color: var(--color-green);
        }

        &.rejected,
        &.cancelled {
          background-color: var(--color-error);
        }
      }

      .offer-actions {
        display: flex;
        justify-content: center;
        gap: 7px;

        padding: 5px;

        button {
          height: 30px;
          min-width: unset;

          padding: 6px;

          display: flex;
          align-items: center;

          &.cancel-button {
            height: auto;
            padding: 1px 10px;
          }
        }
      }

      &-empty {
        width: 100%;
        text-align: center;
        align-self: center;
        background-color: white;
        padding: 30px;
        border-radius: 15px;

        font-size: var(--text-normal);
      }
    }

    &-table-actions {
      text-align: center;

      button {
        min-width: unset;
        font-size: var(--text-small);

        padding: 3px 12px;
      }
    }
  }
}

.manage-offer-modal {
  background-color: var(--color-background-overlay);

  @media (min-width: 768px) {
    .modal-content {
      margin: 5% auto 15% auto;
      background-color: var(--color-background);
      border-radius: 15px;
      max-width: 85%;
      padding: 20px;

      display: flex;
      flex-direction: column;
      gap: 20px;

      position: relative;
      overflow: auto;

      height: 80%;

      .modal-close {
        right: 10px;
        top: 10px;
      }
    }
  }

  @media (min-width: 1024px) {
    .modal-content {
      max-width: 65%;
    }
  }

  @media (min-width: 1280px) {
    .modal-content {
      max-width: 54%;
    }
  }
}