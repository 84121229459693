.tab-container {
  .tab-navigation {
    display: flex;
    align-items: center;

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }

    &-item-element {
      flex: 1;
      padding: 10px 16px;
    }

    &-item {
      font-size: var(--text-small);
      padding: 10px 16px;
      cursor: pointer;
      color: var(--color-normal-gray);
      user-select: none;
      transition: color 0.1s, border 0.1s;
      border-left: 1px solid var(--color-medium-gray);

      &.active {
        color: var(--color-primary);
      }
    }
  }

  .tab-content {
    border-top: 1px solid var(--color-medium-gray);
  }
}
