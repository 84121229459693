.manage-hotel-page-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .manage-hotel-page {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0 10px;

      h1 {
        font-size: var(--text-large);
        font-weight: 600;
      }

      button {
        display: flex;
        align-self: center;
        min-width: unset;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      overflow: visible;

      .hotel-table-tags {
        flex-wrap: wrap;
      }

      .hotel-table-actions {
        display: flex;
        justify-content: center;
        gap: 10px;

        padding: 5px;

        button {
          height: 30px;
          min-width: unset;

          padding: 6px;
        }
      }
    }

    &-card {
      width: 100%;
    }

    &-empty {
      width: 100%;
      max-width: 500px;
      text-align: center;
      align-self: center;
      background-color: white;
      padding: 30px;
      border-radius: 15px;

      margin-top: 100px;

      h2 {
        font-size: 20px;
      }
    }
  }

  @media (min-width: 768px) {
    .manage-hotel-page {
      &-list {
        overflow: auto;

        .hotel-table-tags {
          flex-wrap: unset;
          overflow: auto;
        }
      }
    }
  }
}

.hotel-modal-container {
  background-color: var(--color-background-overlay);

  @media (min-width: 768px) {
    .modal-content {
      margin: 5% auto 15% auto;
      background-color: var(--color-background);
      border-radius: 15px;
      max-width: 65%;
      padding: 20px;

      display: flex;
      flex-direction: column;
      gap: 20px;

      position: relative;
      overflow: auto;

      height: 80%;

      .modal-close {
        right: 10px;
        top: 10px;
      }
    }
  }

  @media (min-width: 1024px) {
    .modal-content {
      max-width: 53%;
    }
  }
}
