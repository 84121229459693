.settings-container {
  .settings-list-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .settings-list {
      &-item {
        display: flex;
        background-color: var(--color-white);
        padding: 16px;
        border-bottom: 1px solid var(--color-lightest-gray);
        gap: 10px;
        align-items: center;

        &:hover {
          background-color: var(--color-background);
        }

        &-icon {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-title {
          flex: 1;
        }
      }
    }
  }
}
