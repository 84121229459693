.message-create-modal-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .message-create-modal {
    &-heading {
      font-size: var(--text-large);
      font-weight: 600;
      width: 85%;
    }

    &-fields {
      display: flex;
      flex-direction: column;
      gap: 20px;

      label {
        font-size: var(--text-small);
        font-weight: 600;
        margin-bottom: 3px;
      }

      input {
        width: 100%;

        &[type="date"] {
          background-color: var(--color-white);
        }
      }

      select {
        background-color: var(--color-white);
      }
    }

    &-form-fields {
      display: flex;
      flex-direction: column;

      .rti--container {
        border: none;
        background: none;
        box-shadow: none;
        padding: 0;
      }

      label {
        height: 25px;
      }

      .editor-container {
        min-height: 150px;
      }
    }

    &-attachments {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 10px;
    }

    &-attachment {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        min-width: unset;
        padding: 0;

        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-buttons {
      margin-top: 50px;

      display: flex;
      gap: 20px;

      button {
        width: 100%;
        min-width: unset;
      }
    }
  }

  .system-error {
    text-align: center;
    margin-top: 10px;
  }

  @media (min-width: 768px) {
    .message-create-modal {
      &-buttons {
        margin-top: 20px;
      }
    }
  }
}

.message-modal-container {
  background-color: var(--color-background-overlay);

  @media (min-width: 768px) {
    .modal-content {
      margin: 5% auto 15% auto;
      background-color: var(--color-background);
      border-radius: 15px;
      max-width: 65%;
      padding: 20px;

      display: flex;
      flex-direction: column;
      gap: 20px;

      position: relative;
      overflow: auto;

      min-height: 80%;
      height: auto;

      .modal-close {
        right: 10px;
        top: 10px;
      }
    }
  }

  @media (min-width: 1024px) {
    .modal-content {
      max-width: 53%;
    }
  }

  @media (min-width: 1440px) {
    .modal-content {
      min-height: 62%;
    }
  }
}
