.manage-configs-page-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  padding-bottom: 80px;

  .manage-configs-page {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: var(--text-large);
        font-weight: 600;
      }

      button {
        display: flex;
        align-self: center;
        min-width: unset;
      }
    }

    &-input {
      display: flex;
      align-items: center;
      gap: 18px;

      label {
        flex: 1;
      }

      button {
        min-width: 100px;
      }
    }

    &-error {
      color: var(--color-error);
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}
