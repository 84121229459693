.profile-info {

  &-header {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &-image {
      width: 200px;
      height: 200px;
      object-fit: cover;
      align-self: center;

      border-radius: 50%;
      box-shadow: 0px 0px 10px 0px var(--color-background-overlay);
    }

    &-no-image {
      width: 100%;
      padding: 30px;
      text-align: center;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: var(--text-large);
      font-weight: 600;
      color: var(--color-text-gray);
    }

    &-detail {
      padding: 0 20px 10px;
      border-bottom: 2px solid var(--color-medium-gray);

      &-name {
        font-size: var(--text-large);
        font-weight: 600;
      }

      &-address {
        display: flex;
        align-items: center;

        font-size: var(--text-normal);
        color: var(--color-normal-gray);

        .location-icon {
          width: 20px;
          height: 20px;
          fill: var(--color-primary);
        }
      }
    }
  }

  &-body,
  &-interest {
    padding: 10px 20px;
    border-bottom: 2px solid var(--color-medium-gray);

    &-label {
      font-size: var(--text-normal);
      margin-bottom: 10px;
      font-weight: 600;
      color: var(--color-normal-gray);
    }

    &-bio {
      font-size: var(--text-small);
    }

    .tags {
      flex-wrap: wrap;
      gap: 20px;

      .tags-item {
        width: auto;
        min-width: 100px;
        padding: 5px 10px;
        border: 2px solid var(--color-black);
        border-radius: 20px;
        text-align: center;
      }
    }
  }

  &-channel {
    padding: 10px 20px;

    &-label {
      font-size: var(--text-normal);
      font-weight: 600;
      color: var(--color-normal-gray);
    }

    &-table {
      img {
        width: 41px;
        height: 41px;
      }

      th {
        padding: 0 10px 5px;

        font-weight: 500;
        font-size: var(--text-normal);
      }

      td:not(:first-child) {
        padding: 0 10px 10px;
        text-align: center;
        align-items: center;

        font-weight: 600;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      margin-bottom: 10px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      img {
        width: 41px;
        height: 41px;
      }

      span {
        min-width: 60px;
      }

      &.data {
        font-weight: 700;
      }
    }
  }

  &-calendar {

    &-container {

      .profile-calendar-tile {
        padding: 10px 3.6667px;

        &-available {
          background-color: var(--color-light-green);
        }

        abbr {
          font-size: var(--text-extrasmall);
        }
      }
    }
  }
}