.contact-container {
  max-width: 1024px;
  margin: 0 auto;

  .contact-description {
    padding: 20px 10px;
    border-top: 1px solid var(--color-medium-gray);
    display: flex;

    span:first-child {
      font-weight: 700;
      width: 100px;
    }
  }
}
