.campaign-create-modal-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .campaign-create-modal {
    &-heading {
      font-size: var(--text-large);
      font-weight: 600;
      width: 85%;
    }

    &-fields {
      display: flex;
      flex-direction: column;
      gap: 20px;

      label {
        font-size: var(--text-small);
        font-weight: 600;
        margin-bottom: 3px;
      }

      input {
        width: 100%;

        &[type="date"] {
          background-color: var(--color-white);
        }
      }

      select {
        background-color: var(--color-white);
      }
    }

    &-form-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .campaign-create-modal-form-fields {
        width: 100%;
      }
    }

    &-form-fields {
      display: flex;
      flex-direction: column;

      .rti--container {
        border: none;
        background: none;
        box-shadow: none;
        padding: 0;
      }

      label {
        height: 25px;
      }

      .campaign-available-channel {
        padding: 5px;
        display: flex;
        gap: 5px;
        cursor: pointer;

        span {
          min-width: 70px;
        }

        input[type="checkbox"] {
          accent-color: var(--color-light-green);
          width: 20px;
          height: 20px;
        }
      }
    }

    &-buttons {
      margin-top: 20px;

      display: flex;
      gap: 20px;

      button {
        width: 100%;
        min-width: unset;
      }
    }

    &-image-upload {
      width: 100%;
    }

    &-calendar {
      .campaign-calendar-tile {
        padding: 10px 3.6667px;

        &-available {
          background-color: var(--color-light-green);
        }

        abbr {
          font-size: var(--text-extrasmall);
        }
      }
    }
  }

  .system-error {
    text-align: center;
    margin-top: 10px;
  }

  .image-form-fields {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
  }

  @media (min-width: 768px) {
    .image-form-fields {
      flex-direction: row;
    }

    .campaign-create-modal {
      &-image-upload {
        width: 200px;
      }

      &-form-container {
        flex-direction: row;
        justify-content: space-between;

        .campaign-create-modal-form-fields {
          width: 50%;
        }
      }
    }
  }
}

.campaign-modal-container {
  background-color: var(--color-background-overlay);

  @media (min-width: 768px) {
    .modal-content {
      margin: 5% auto 15% auto;
      background-color: var(--color-background);
      border-radius: 15px;
      max-width: 65%;
      padding: 20px;

      display: flex;
      flex-direction: column;
      gap: 20px;

      position: relative;
      overflow: auto;

      height: 80%;

      .modal-close {
        right: 10px;
        top: 10px;
      }
    }
  }

  @media (min-width: 1024px) {
    .modal-content {
      max-width: 53%;
    }
  }
}