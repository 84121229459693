.factor-container {
  height: calc(100vh - 142px);
  background-color: var(--color-white);
  padding: 20px 10px;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .factor-icon {
    svg {
      width: 100px;
      height: 100px;
      fill: var(--color-primary);
    }
  }

  .factor-title {
    margin-top: 10px;

    &-status {
      margin-top: 20px;
      font-weight: 600;

      span {
        color: var(--color-error);
      }

      &.active {
        span {
          color: var(--color-primary);
        }
      }
    }
  }

  .factor-information {
    height: 100%;
    display: flex;
    flex-direction: column;

    &-description {
      font-size: var(--text-extrasmall);
      color: var(--color-text-gray);
    }

    &-qr {
      margin: 10px 0;
    }

    &-secret {
      span {
        font-size: var(--text-extrasmall);
      }

      &-detail {
        margin: 10px 0;

        border: 1px solid var(--color-medium-gray);
        padding: 10px;
      }
    }

    &-otp {
      height: 100%;
    }
  }

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .factor-form-field {
      display: flex;
      flex-direction: column;
      gap: 20px;

      label {
        font-weight: 600;
      }

      input {
        border-radius: 5px;
        padding: 10px;
      }
    }

    .factor-information-otp-field {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      label {
        font-weight: 600;
      }

      input {
        width: 100%;
        border-radius: 5px;
        padding: 10px;
      }
    }
  }
}
